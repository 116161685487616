.about_image {
  width: 100%;
}

.about_wrapper {
  background-color: #fff5fe;
  height: 650px;
  width: 100%;
  padding: 40px 70px;
  box-sizing: border-box;

  @media (max-width: 650px) {
    padding: 20px;
  }

  @media (max-width: 400px) {
    height: 500px;
  }
}

.about_context {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/bg_flower.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  p {
    font-size: 22px;
    color: #000;
    text-align: center;
    width: 65%;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  .about_numbers {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 50px;

    .about_numbers_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      h4 {
        font-size: 40px;
        color: #9e5d71;
        margin: 0;
      }

      p {
        font-size: 14px;
        color: #000;
        margin: 0;
        width: 200px;
      }
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 16px;
    }

    .about_numbers {
      gap: 20px;

      .about_numbers_item {
        h4 {
          font-size: 30px;
        }

        p {
          font-size: 12px;
          width: 150px;
        }
      }
    }
  }
}
