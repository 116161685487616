.marquee h4 {
  margin: 50px 0 20px 30px;
  font-weight: 500;
  color: #b259a4;

  @media (max-width: 678px) {
    margin: 10px;
    font-size: 14px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px;
  box-sizing: border-box;
  max-width: 1400px;
  margin: 0 auto;
  gap: 70px;

  /* Mobile responsiveness */
  @media (max-width: 950px) {
    gap: 20px;
    padding: 30px;
    box-sizing: border-box;
  }
  @media (max-width: 850px) {
    flex-direction: column;
    padding: 0 20px 20px 20px;
    gap: 0px;
  }
}

.content {
  max-width: 60%;
  color: #333;

  h3 {
    font-size: 32px;
    color: #9e5d71;
    margin-top: 0;
    margin-bottom: 40px;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 70px;
    width: 80%;
    line-height: 40px;

    @media (max-width: 1270px) {
      width: 100%;
    }
  }
  @media (max-width: 1200px) {
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 1000px) {
    max-width: 50%;
  }

  button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 16px;
    color: #9e5d71;
    background-color: transparent;
    border: 2px solid #9e5d71;
    cursor: pointer;
    transition: background-color 0.3s ease;

    svg {
      margin-left: 10px;
    }

    &:hover {
      background-color: #9e5d71;
      color: white;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  /* Mobile adjustments */
  @media (max-width: 850px) {
    max-width: 100%;
    text-align: center;

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      margin: 10px auto;
    }

    button {
      float: right;
    }
  }

  @media (max-width: 678px) {
    h3 {
      font-size: 20px;
      margin-bottom: 0px;
    }
  }
}

.picture {
  max-width: 40%;
  height: auto;
  object-fit: cover;
  // border-radius: 10px;

  @media (max-width: 1000px) {
    max-width: 50%;
  }

  /* Mobile adjustments */
  @media (max-width: 850px) {
    max-width: 100%;
    margin-top: 10px;
  }
}
