.banner_container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  gap: 30px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media (max-width: 1100px) {
      gap: 10px;
    }
    @media (max-width: 1000px) {
      gap: 0px;
    }
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #b259a4;
      margin: 0;
    }
    p {
      color: #000000;
      text-align: center;
      line-height: 24px;
    }

    button {
      background-color: transparent;
      color: #9747ff;
      border: 1px solid #9747ff;
      padding: 10px 30px;
      font-weight: 600;
      transition: all 0.3s ease;
      &:hover {
        background-color: #9747ff;
        color: #fff;
      }
    }
  }

  img {
    width: 55%;
    aspect-ratio: 16/11;
    object-fit: cover;
  }
}

@media (max-width: 888px) {
  .banner_container {
    flex-direction: column-reverse;
    gap: 20px;
    padding: 10px;
    .content {
      gap: 10px;
      h2 {
        font-size: 24px;
      }
    }
    img {
      width: 100%;
    }
  }
}

@media (max-width: 550px) {
  .banner_container {
    .content {
      h2 {
        font-size: 20px;
      }

      p {
        font-size: 12px;
      }

      button {
        font-size: 12px;
        padding: 10px 20px;
      }
    }
  }
}
