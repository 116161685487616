.marquee_container {
  position: relative;
  .marquee {
    h4 {
      margin: 20px 0 20px 30px;
      font-weight: 500;
      color: #b259a4;

      @media (max-width: 678px) {
        margin: 10px;
        font-size: 14px;
      }
    }
  }
  h3 {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #7e5d9e;
    background-color: white;
    padding: 5px 30px;
    box-sizing: border-box;
    font-size: 24px;
    margin: 0;

    @media (max-width: 678px) {
      font-size: 18px;
    }

    @media (max-width: 415px) {
      padding: 5px 10px;
    }
  }
}

.cards {
  width: 100%;
  padding: 40px 50px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 850px) {
    padding: 40px 20px 20px 20px;
    box-sizing: border-box;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .info {
      padding: 20px 0 20px 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;
      background-position: center;
      background-size: cover;
      height: 280px;

      h3 {
        color: white;
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        margin: 60px 0 40px 0;
      }

      a {
        color: white;
        text-decoration: none;
        font-size: 16px;
        border: 1px solid white;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        transition: 0.5s ease;
        gap: 10px;

        &:hover {
          background-color: white;
          color: #7e5d9e;
          transition: 0.5s ease;
          cursor: pointer;
        }
      }
    }

    button {
      width: 100%;
      padding: 10px 15px;
      outline: none;
      border: 1px solid #000;
      background-color: white;
      cursor: pointer;
      transition: 0.5s ease;

      &:hover {
        background-color: #7e5d9e;
        color: white;
        transition: 0.5s ease;
      }
    }
  }

  @media (max-width: 530px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px;

    .card {
      .info {
        width: 100%;
        h3 {
          font-size: 14px;
        }
      }
    }
  }
}
