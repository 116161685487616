.mu_banner {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);

  @media (max-width: 768px) {
    height: calc(100vh - 100px);
  }
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper_slide img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.mu_banner__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 99; /* Ensures content stays on top of the banner images */
  text-align: center;
  width: 80%;
}

.mu_banner__content h1 {
  font-size: 64px;
  font-weight: 500;
  color: white;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adds shadow for better visibility */
}

.mu_banner__content p {
  font-weight: 700;
  color: white;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow for better text readability */
}

.mu_banner__content a {
  font-weight: 500;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 12px 20px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.mu_banner__content a:hover {
  background-color: white;
  color: #db94a4;
}

@media (max-width: 700px) {
  .mu_banner {
    height: calc(100vh - 80px);
  }

  .mu_banner__content {
    gap: 10px;
  }

  .mu_banner__content h1 {
    font-size: 40px;
  }

  .mu_banner__content p,
  .mu_banner__content a {
    font-size: 14px;
  }
}
