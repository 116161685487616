.all_apparatus {
  background: #f4f4f4;
}

.marquee_container {
  position: relative;
  .marquee {
    h4 {
      margin: 20px 0 20px 30px;
      font-weight: 500;
      color: #9747ff;

      @media (max-width: 678px) {
        margin: 10px;
        font-size: 14px;
      }
    }
  }
  h3 {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #7e5d9e;
    background-color: #f4f4f4;
    padding: 5px 30px;
    box-sizing: border-box;
    font-size: 24px;
    margin: 0;

    @media (max-width: 678px) {
      font-size: 18px;
    }

    @media (max-width: 415px) {
      padding: 5px 10px;
    }
  }
}

.apparatus {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 40px 50px;
  box-sizing: border-box;

  .apparatus_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    img {
      width: 100%;
      height: 300px;
      object-fit: contain;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.1);
        transition: all 0.5s;
      }
    }
  }

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 650px) {
    padding: 30px 20px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
