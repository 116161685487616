header {
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 100px;
    padding: 0 50px;
  }

  @media (max-width: 560px) {
    padding: 0 20px;
    height: 80px;
  }

  .logo {
    height: 70%;
    width: auto;

    img {
      height: 100%;
      width: auto;
    }
  }

  nav ul {
    display: flex;
    list-style: none;
    gap: 40px;
    padding: 0;

    li {
      font-size: 20px;

      a {
        text-decoration: none;
        color: black;
      }
    }
    @media (max-width: 640px) {
      gap: 20px;
      li a {
        font-size: 16px;
      }
    }

    @media (max-width: 560px) {
      display: none;
    }
  }

  .contact {
    text-decoration: none;
    color: black;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 975px) {
      color: #b259a4;
      p {
        display: none;
      }
    }

    @media (max-width: 560px) {
      display: none;
    }
  }

  .menu_bar {
    display: none;
    @media (max-width: 560px) {
      display: block;
      font-size: 24px;
      color: #b259a4;
    }
  }

  .mobile_menu {
    transform: translateX(-100%);
    transition: ease 0.5s;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: #9e5d71; /* Existing color */
    z-index: 1000;
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2); /* Shadow effect for depth */
    padding: 20px 0; /* Space around the menu */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-align: center;

      li {
        font-size: 24px;
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        cursor: pointer;
        transition: color 0.3s ease, background-color 0.3s ease;

        &:hover {
          color: #f3e6e8; /* Lighter hover color */
          background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
        }
      }
    }

    a {
      color: #fff;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 18px;
      padding: 10px 0;
      transition: color 0.3s ease, transform 0.3s ease;

      &:hover {
        color: #f3e6e8;
        transform: scale(1.05); /* Slight zoom effect */
      }
    }
  }

  .active_menu {
    transform: translateX(0);
    opacity: 1;
    transition: ease 0.5s;
    // animation: menuopener 0.5s ease;
  }

  // @keyframes menuopener {
  //   0% {
  //     opacity: 0;
  //     transform: translateX(-100%);
  //   }
  //   100% {
  //     opacity: 1;
  //     transform: translateX(0);
  //   }
  // }
}
