.location_container {
  display: flex;
  width: 100%;

  img {
    width: 50%;
    aspect-ratio: 1/1;
  }

  .location_info {
    width: 50%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 70px;
    color: #b259a4;
    background-color: #f4f4f4;

    .contact {
      display: flex;
      flex-direction: column;
      gap: 20px;
      h3 {
        margin: 0;
      }

      a {
        color: #b259a4;
        text-decoration: none;
        transition: 0.3s;
        cursor: pointer;
      }
    }

    .location {
      display: flex;
      flex-direction: column;
      h3 {
        margin: 0;
      }
      p {
        max-width: 370px;
        line-height: 32px;
      }
    }
  }

  @media (max-width: 650px) {
    flex-direction: column-reverse;
    margin: 0;

    .location_info {
      width: 100%;
      padding: 20px;
      gap: 30px;
    }

    img {
      width: 100%;
    }
  }
}
