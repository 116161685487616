.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup_content {
  background-color: #f8e6f5;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

h2 {
  color: #ab62d9;
  margin-bottom: 20px;
}

.popup_form {
  display: flex;
  flex-direction: column;
}

.popup_form label {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  color: #ab62d9;

  b {
    margin-right: 10px;
  }
}

.popup_form input {
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ab62d9;
  background-color: transparent;
  color: #ab62d9;
  outline: none;
}

input::placeholder {
  color: #c35eb3;
}

.popup_form button {
  background-color: transparent;
  border: 1px solid #ab62d9;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 70%;
  margin: 0 auto;
  cursor: pointer;
  color: #ab62d9;
  transition: background-color 0.3s ease;
}

.popup_form button:hover {
  background-color: #ab62d9;
  color: #fff;
  transition: background-color 0.3s ease;
}

.close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #ab62d9;
  background: none;
  border: none;
  cursor: pointer;
}

.popup_content img {
  margin-top: 10px;
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .close_btn {
    top: 5px;
    right: 5px;
  }
}
