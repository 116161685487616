footer {
  display: flex;
  padding: 100px 50px;
  box-sizing: border-box;
  gap: 40px;

  @media (max-width: 585px) {
    padding: 40px 20px;
    gap: 40px;
  }
  @media (max-width: 521px) {
    padding: 40px 20px;
    gap: 30px;
  }

  .social {
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 678px) {
      margin-right: 0;
    }

    img {
      width: 100px;
    }

    .social_links {
      display: flex;
      gap: 30px;
      a {
        font-size: 24px;
        text-decoration: none;
        cursor: pointer;
        color: #000;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    h3 {
      color: #0000009b;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      li {
        cursor: pointer;
        line-height: 28px;
        a {
          text-decoration: none;
          color: #0000009b;
        }
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 20px;

    .social {
      gap: 20px;
    }
  }
}
