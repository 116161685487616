.partner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  padding: 40px 60px;
  box-sizing: border-box;

  h3 {
    font-size: 24px;
    margin: 0;
  }

  .partners {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 20px;

    img {
      height: 120px;
      object-fit: cover;

      &:last-child {
        height: 160px;
      }
    }
  }

  @media (max-width: 700px) {
    padding: 30px 20px;

    .partners img {
      height: 100px;
    }
  }

  @media (max-width: 525px) {
    .partners img {
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
